import styled, { css } from 'styled-components'

import { ValueName } from '../AttributeTab/AttributeValueGroupItem.styled'

export const PopupContent = styled.div`
  padding-top: 11px;
  margin: 0 24px;
  border-bottom: 1px solid rgba(229, 231, 241, 1);
  border-top: 1px solid rgba(229, 231, 241, 1);
  height: 635px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18.5px 24px;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  width: 100%;
`

type ButtonProps = {
  disabled?: boolean
}

export const Button = styled.div`
  padding: 8px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(53, 59, 96, 1);
  background: rgba(53, 59, 96, 0.05);

  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.07);
  }
  &:active {
    background: rgba(53, 59, 96, 0.09);
  }
  ${({ disabled }: ButtonProps) =>
    disabled
      ? css`
          pointer-events: none;
          background: rgba(53, 59, 96, 0.03);
          color: rgba(53, 59, 96, 0.09);
        `
      : ''}
`
export const StyledButton = styled(Button)`
  color: #fff;
  background: rgba(76, 94, 207, 1);
  &:hover {
    background: rgba(63, 82, 198, 1);
  }
  &:active {
    background: rgba(50, 70, 190, 1);
  }
  ${({ disabled }: ButtonProps) =>
    disabled
      ? css`
          pointer-events: none;
          background: rgba(53, 59, 96, 0.03);
          color: rgba(53, 59, 96, 0.09);
        `
      : ''}
`
export const Text = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 61, 1);
  margin: 0 24px;
  padding-bottom: 16px;
`

export const StyledValueName = styled(ValueName)`
  width: max-content;
  margin-bottom: 15px;
  margin-left: 24px;
`

type GroupListItemProps = {
  selected: boolean
}

export const GroupListItem = styled.div`
  padding: 6px 9px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: rgba(0, 0, 61, 1);
  &:hover {
    cursor: pointer;
    background: rgba(244, 244, 248, 1);
  }
  ${({ selected }: GroupListItemProps) =>
    selected
      ? css`
          background: rgba(76, 94, 207, 0.1) !important;
        `
      : ''}
  margin-bottom: 4px;
`
