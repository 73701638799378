import { useEffect, useState } from 'react'

import { IconButton, Popup, RoundedCross, Select } from '@gmini/ui-kit'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { addUserRoles, deleteUserRoles } from '../../store/users/actions'
import { User } from '../../store/users/types'

import { CompaniesIcon } from '../icons/CompaniesIcon'

import { getProjects } from '../../store/projects/actions'

import { SelectMultipleRolesSingleInput } from '../SelectMultipleRolesSingleInput/SelectMultipleRolesSingleInput'

import {
  Button,
  Container,
  Footer,
  Header,
  Label,
  PopupContent,
  ProjectName,
  ProjectWrapper,
  SelectProjectWrapper,
  StyledButton,
  Title,
} from './EditProjectRolesPopup.styled'

type EditProjectRolesPopupProps = {
  open: boolean
  onClose: () => void
  user: User
  getUser: (id: string) => Promise<void>
  initProjectUrn: string
}

export const EditProjectRolesPopup = ({
  open,
  onClose,
  user,
  getUser,
  initProjectUrn,
}: EditProjectRolesPopupProps) => {
  const { userRoles: roles, id, name } = user
  const joinedProjects = roles.reduce(
    (acc: { [x: string]: boolean }, r) => ({ ...acc, [r.projectUrn]: true }),
    {},
  )
  const [projectUrn, setProjectUrn] = useState(initProjectUrn)
  const { list: projects, setByUrn } = useAppSelector(state => state.projects)

  const initRoles = roles.find(r => r.projectUrn === projectUrn)?.roles || []
  const initRoleIds = initRoles.map(r => r.id)
  const [roleIds, setRoleIds] = useState<number[]>(initRoleIds)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setRoleIds(initRoleIds), [user, open, projectUrn])
  useEffect(() => setProjectUrn(initProjectUrn), [initProjectUrn])

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  const handleSubmit = async () => {
    const roleIdsToAdd = roleIds.filter(id => !initRoleIds.includes(id))
    const roleIdsToDelete = initRoles
      .filter(({ id }) => !roleIds.includes(id))
      .map(({ id }) => id)
    const promises = []
    if (roleIdsToDelete.length) {
      promises.push(
        dispatch(
          deleteUserRoles({
            id,
            roleIds: roleIdsToDelete,
            projectUrn,
            name,
            toastrText: 'Изменены роли пользователя',
          }),
        ),
      )
    }
    if (roleIdsToAdd.length) {
      promises.unshift(
        dispatch(
          addUserRoles({
            id,
            roleIds: roleIdsToAdd,
            projectUrn,
            name,
            toastrText: promises.length ? '' : 'Изменены роли пользователя',
          }),
        ),
      )
    }
    await Promise.all(promises)
    if (promises.length) {
      getUser(id)
    }
    onClose()
  }
  return (
    <Popup width={'530px'} open={open} onClose={onClose}>
      <Container>
        <Header>
          <Title data-test-id='editProjectRolesPopupHeading'>
            {initProjectUrn ? 'Редактировать роли' : 'Добавить проект'}
          </Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeEditProjectRolesPopupBtn'
              onClick={onClose}
            />
          </IconButton>
        </Header>
        <PopupContent>
          {!initProjectUrn ? (
            <SelectProjectWrapper>
              <Label>Проект</Label>
              <Select
                getOptionLabel={o => o.name}
                options={projects.filter(p => !joinedProjects[p.urn])}
                onChange={o => setProjectUrn(o?.urn || '')}
                emptyOptionListMessage='Нет доступных совпадений'
                value={setByUrn[projectUrn]}
                placeholder='Выберите проект'
                data-test-id='editProjectRolesPopupProjectInput'
                optionDataTestIdPrefix='editProjectRolesPopupProjectInputOption'
              />
            </SelectProjectWrapper>
          ) : (
            <ProjectWrapper>
              <CompaniesIcon width={32} height={32} />
              <ProjectName>{setByUrn[projectUrn]?.name}</ProjectName>
            </ProjectWrapper>
          )}
          <Label>Роль</Label>
          <SelectMultipleRolesSingleInput
            roleIds={roleIds}
            setRoleIds={setRoleIds}
          />
        </PopupContent>
        <Footer>
          <Button
            data-test-id='editProjectRolesPopupCancelBtn'
            onClick={onClose}
          >
            Отменить
          </Button>

          <StyledButton
            data-test-id='editProjectRolesPopupConfirmBtn'
            disabled={!projectUrn || !roleIds.length}
            onClick={handleSubmit}
          >
            {initProjectUrn ? 'Сохранить' : 'Добавить'}
          </StyledButton>
        </Footer>
      </Container>
    </Popup>
  )
}
