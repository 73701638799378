import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'

import { apiClient } from '../../services/api'
import { Module } from '../roles/types'

import { CreateRoleRequest, Role, UpdateRoleRequest } from './types'

export const getResources = createAsyncThunk<Module[], void>(
  'roles/getResources',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<Module[]>({
      path: '/auth/resources',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: Module[] = get(res, 'data.data', [])

    return data
  },
)

export const getRoles = createAsyncThunk<Role[], { projectUrn: string }>(
  'roles/getRoles',
  async ({ projectUrn }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<Role[]>({
      path: '/auth/admin/roles',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: Role[] = get(res, 'data.data', [])

    return data
  },
)

export const createRole = createAsyncThunk<Role, CreateRoleRequest>(
  'roles/createRole',
  async ({ description, title }, { rejectWithValue }) => {
    const [err, res] = await apiClient.post<CreateRoleRequest, Role>({
      path: '/auth/admin/roles',
      body: {
        title,
        description,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          Роль {`"${title}"`} добавлена. Настройте доступ к модулям.
        </SnackbarWrapper>
      ),
    })

    const data: Role = get(res, 'data.data', {
      id: -1,
      title: '',
      description: '',
      system: false,
      resources: {},
    })

    return data
  },
)

export const updateRole = createAsyncThunk<Role, UpdateRoleRequest>(
  'roles/updateRole',
  async ({ id, description, title, permissions }, { rejectWithValue }) => {
    const [err, res] = await apiClient.put<Omit<UpdateRoleRequest, 'id'>, Role>(
      {
        path: `/auth/admin/roles/${id}`,
        body: {
          title,
          description,
          permissions,
        },
      },
    )

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          Изменения в роли {`"${title}"`} сохранены.
        </SnackbarWrapper>
      ),
    })

    const data: Role = get(res, 'data.data', {
      id: -1,
      title: '',
      description: '',
      system: false,
      resources: {},
    })

    return data
  },
)

export const deleteRole = createAsyncThunk<Role, { id: number }>(
  'roles/deleteRole',
  async ({ id }, { rejectWithValue }) => {
    const [err, res] = await apiClient.delete<Role>({
      path: `/auth/admin/roles/${id}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: Role = get(res, 'data.data', {
      id: -1,
      title: '',
      description: '',
      system: false,
      resources: {},
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>Роль {`"${data.title}"`} удалена.</SnackbarWrapper>
      ),
    })

    return data
  },
)
