import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`
export const ProjectsColumn = styled.div`
  width: 280px;
`
export const ProjectsRolesColumn = styled.div`
  display: flex;
  align-items: center;
`
