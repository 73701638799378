import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const AttributeTabTabWrapper = styled.div`
  padding: 24px;
  display: flex;
  align-items: flex-start;
  height: 100%;
`

export const AttributeTabContentBlock = styled.div`
  width: 498px;
  overflow: hidden;
  height: 100%;
  flex-shrink: 0;
`

export const AttributeTabListHeader = styled.div`
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: rgba(0, 0, 61, 1);
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(229, 231, 241, 1);
`

export const ListHeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const AddAttributeValueButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  background: rgba(53, 59, 96, 0.05);
  padding: 6px 8px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(53, 59, 96, 1);
  cursor: pointer;
  &:hover {
    background: rgba(53, 59, 96, 0.08);
  }
  &:active {
    background: rgba(53, 59, 96, 0.1);
  }
`

export const AttributeTabList = styled.div`
  overflow-y: auto;
  max-height: 81%;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }
`
export const DeletePopupTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 61, 1);
  margin: 6px 2px;
`
export const TabRightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 71px;
  width: 100%;
`

export const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  svg {
    padding: 5px;
    flex-shrink: 0;
  }
`
