import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Tooltip } from '@gmini/ui-kit'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { exportUsersXls, getAllUsers } from '../../store/users/actions'
import { User } from '../../store/users/types'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import {
  AddUserButton,
  ButtonContainer,
  ExportUsersButton,
  ListHeading,
  Title,
  TitleCount,
  UserListWrapper,
} from './UsersPage.styled'

const limit = 100

export const UsersPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] =
    useState(false)
  const [unsavedUserChanges, setUnsavedUserChanges] = useState(false)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)

  const getUser = useCallback(
    async (id: string) => {
      const users = await dispatch(getAllUsers()).unwrap()
      const user = users.find(user => user.id === id)
      setUserDetails(user || null)
    },
    [dispatch, setUserDetails],
  )

  const handleExportUsers = () => {
    dispatch(exportUsersXls())
  }

  useEffect(() => {
    const unblock = history.block(() => {
      if (unsavedUserChanges) {
        setIsUnsavedChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, unsavedUserChanges])

  const {
    list: users,
    total,
    pending,
  } = useAppSelector(state => state.allUsers)
  // TODO: заготовки под пагинацию
  // const [visibleUsers, setVisibleUsers] = useState(users.slice(0, limit))
  // TODO: заготовки под пагинацию
  // useEffect(() => {
  //   setVisibleUsers(users.slice(0, limit))
  // }, [users])

  // const fetchAllUsers = useCallback(
  // ({ offset, limit }: { offset: number; limit: number }) => {
  //TODO: когда можно будет реализовать нормальную пагинацию
  // dispatch(getAllUsers({ offset, limit }))
  // TODO: заготовки под пагинацию
  // setVisibleUsers(users.slice(0, offset))
  // },
  // [],
  // )

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <UsersIcon />
          <span data-test-id='usersPageHeading'>Пользователи</span>
          <TitleCount>{total}</TitleCount>
        </Title>
        <ButtonContainer>
          <Tooltip title='Выгрузить список пользователей'>
            <ExportUsersButton
              data-test-id='exportUsersBtn'
              onClick={() => handleExportUsers()}
            />
          </Tooltip>
          <AddUserButton
            data-test-id='addUserBtn'
            onClick={() => setAddUserPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<AddUserIcon width='16px' height='16px' />}
          >
            <span>Добавить пользователя</span>
          </AddUserButton>
        </ButtonContainer>
      </ListHeading>
      <AddUserPopup
        open={addUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}
        withoutRoles
      />
      <EditUserDrawer
        getUser={getUser}
        userState={userDetails}
        setUserState={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setUnsavedUserChanges={setUnsavedUserChanges}
        isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
      <UserList
        localStoragePrefix='all'
        noExtendedEditOrDelete
        users={users}
        total={total}
        limit={limit}
        // fetchUserList={fetchAllUsers}
        excludeProjectRolesField
        pending={pending}
        setUserDetails={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
    </UserListWrapper>
  )
}
