import { Pointer } from '@gmini/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getProjectUsers } from '../../store/users/actions'
import { User } from '../../store/users/types'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import { getProjects } from '../../store/projects/actions'

import {
  AddUserButton,
  BackButton,
  ButtonContainer,
  ListHeading,
  Title,
  TitleCount,
  UserListWrapper,
} from './ProjectPage.styled'

export const ProjectPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { setByUrn, total: totalProjects } = useAppSelector(
    state => state.projects,
  )
  const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] =
    useState(false)
  const [unsavedUserChanges, setUnsavedUserChanges] = useState(false)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)
  const { projectUrn, hubId } = useParams<{
    projectUrn: string
    hubId: string
  }>()
  const {
    list: users,
    total,
    pending,
  } = useAppSelector(state => state.projectUsers)

  useEffect(() => {
    if (!totalProjects) {
      dispatch(getProjects())
    }
  }, [dispatch, totalProjects])

  const projectName = setByUrn[projectUrn]?.name

  const getUser = useCallback(
    async (id: string) => {
      const users = await dispatch(getProjectUsers({ projectUrn })).unwrap()
      const user = users.find(user => user.id === id)
      setUserDetails(user || null)
    },
    [dispatch, setUserDetails, projectUrn],
  )

  useEffect(() => {
    const unblock = history.block(() => {
      if (unsavedUserChanges) {
        setIsUnsavedChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, unsavedUserChanges])

  useEffect(() => {
    dispatch(getProjectUsers({ projectUrn }))
  }, [dispatch, projectUrn])

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <BackButton
            data-test-id='backToHubBtn'
            onClick={() => {
              if (unsavedUserChanges) {
                setIsUnsavedChangesPopupOpen(true)
                return
              }
              history.push(`/hub/${hubId}`)
            }}
          >
            <Pointer />
          </BackButton>
          <UsersIcon />
          <span data-test-id='projectPageHeading'>
            Пользователи проекта {projectName}
          </span>
          <TitleCount>{total}</TitleCount>
        </Title>
        <ButtonContainer>
          <AddUserButton
            data-test-id='addUserBtn'
            onClick={() => setAddUserPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<AddUserIcon width='16px' height='16px' />}
          >
            <span>Добавить пользователя</span>
          </AddUserButton>
        </ButtonContainer>
      </ListHeading>
      <AddUserPopup
        open={addUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}
      />
      <EditUserDrawer
        inProject
        userState={userDetails}
        setUserState={setUserDetails}
        getUser={getUser}
        unsavedUserChanges={unsavedUserChanges}
        setUnsavedUserChanges={setUnsavedUserChanges}
        isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
      <UserList
        localStoragePrefix='project'
        users={users}
        pending={pending}
        setUserDetails={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
        inProject
      />
    </UserListWrapper>
  )
}
