import { Pointer } from '@gmini/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { modulesNames } from '../../store/modules/types'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getModuleUsers } from '../../store/users/actions'
import { User } from '../../store/users/types'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import {
  BackButton,
  ListHeading,
  Title,
  TitleCount,
  UserListWrapper,
} from './ModulePage.styled'

export const ModulePage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { moduleName, hubId } = useParams<{
    hubId: string
    moduleName: keyof typeof modulesNames
  }>()
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] =
    useState(false)
  const [unsavedUserChanges, setUnsavedUserChanges] = useState(false)

  const getUser = useCallback(
    async (id: string) => {
      const users = await dispatch(getModuleUsers({ moduleName })).unwrap()
      const user = users.find(user => user.id === id)
      setUserDetails(user || null)
    },
    [dispatch, setUserDetails, moduleName],
  )

  const {
    list: users,
    total,
    pending,
  } = useAppSelector(state => state.moduleUsers)

  useEffect(() => {
    dispatch(getModuleUsers({ moduleName }))
  }, [dispatch, moduleName])

  useEffect(() => {
    const unblock = history.block(() => {
      if (unsavedUserChanges) {
        setIsUnsavedChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, unsavedUserChanges])

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <BackButton
            data-test-id='backToModulesBtn'
            onClick={() => history.push(`/hub/${hubId}/modules`)}
          >
            <Pointer />
          </BackButton>
          <UsersIcon />
          <span data-test-id='moduleUsersHeading'>
            Пользователи модуля {modulesNames[moduleName]}
          </span>
          <TitleCount>{total}</TitleCount>
        </Title>
      </ListHeading>
      <EditUserDrawer
        noRolesEdit
        getUser={getUser}
        userState={userDetails}
        setUserState={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setUnsavedUserChanges={setUnsavedUserChanges}
        isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
      <UserList
        localStoragePrefix='module'
        noExtendedEditOrDelete
        users={users}
        pending={pending}
        setUserDetails={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
    </UserListWrapper>
  )
}
