import { ActionPopupWrapper, Select, TextField } from '@gmini/ui-kit'

import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { useParams } from 'react-router'

import { toastr } from 'react-redux-toastr'

import { isNotEmpty } from '@gmini/utils'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  addUserRoles,
  createUser,
  getAllUsers,
  getProjectUsers,
  updateUser,
} from '../../store/users/actions'
import { getRoles } from '../../store/roles/actions'

import { RoleBadge } from '../RoleBadge/RoleBadge'
import { AdditionalRolesCount } from '../RoleBadge/RoleBadge.styled'

import { Avatar } from '../icons/Avatar'

import { User, UserType } from '../../store/users/types'
import { Company } from '../../store/companies/types'
import { UserTypeInfoPanel } from '../UserTypeInfoPanel/UserTypeInfoPanel'

import { SelectMultipleRoles } from '../SelectMultipleRoles/SelectMultipleRoles'

import { getCompanies } from '../../store/companies/actions'

import {
  EmailAndPhone,
  EmptyContainer,
  FieldContainer,
  FieldError,
  FieldLabel,
  Form,
  Name,
  SelectWrapper,
  TextBlock,
  UserBadge,
  UserInfoWrapper,
  UserOption,
} from './AddUserPopup.styled'

type AddUserPopupProps = {
  open: boolean
  onClose: () => void
  withoutRoles?: boolean
}
const requiredErrorMessage = 'Это поле является обязательным'
const maxLengthErrorMessage = 'Максимальное количество символов - 40'

const defaultValues: {
  user: User | null
  firstName: string
  lastName: string
  phone: string
  company: Company | null
} = {
  user: null,
  firstName: '',
  lastName: '',
  phone: '',
  company: null,
}

const getUserType = (user: User, projectUrn: string): UserType => {
  const flatProjectUrns = user.userRoles.flatMap(r => r.projectUrn)
  if (user.id === UserType.NEW) {
    return UserType.NEW
  } else if (flatProjectUrns.includes(projectUrn)) {
    return UserType.PROJECT
  }
  return UserType.ACCOUNT
}

const popupButtonsDataTestIds = {
  acceptButton: 'addUserPopupConfirmBtn',
  declineButton: 'addUserPopupCancelBtn',
}

export const AddUserPopup = ({
  open,
  onClose,
  withoutRoles,
}: AddUserPopupProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({ mode: 'onChange', defaultValues })
  const params = useParams<{ projectUrn: string }>()
  const { projectUrn } = params
  const dispatch = useAppDispatch()
  const { list: allUsers, pending: allUsersPending } = useAppSelector(
    state => state.allUsers,
  )
  const { list: companies, setById: companiesSet } = useAppSelector(
    state => state.companies,
  )
  const [roleIdsState, setRoleIdsState] = useState<(number | null)[]>([null])
  const [userType, setUserType] = useState<UserType | null>(null)
  const [newUserEmail, setNewUserEmail] = useState('')
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [createUserPending, setCreateUserPending] = useState(false)

  useEffect(() => {
    if (newUserEmail && !newUserEmail.match(/.+@.+\..+/gmu)) {
      setError('user', { message: 'Неверный формат' })
    } else {
      clearErrors('user')
    }
  }, [newUserEmail, clearErrors, setError])

  useEffect(() => {
    dispatch(getAllUsers())
    dispatch(getCompanies())
  }, [dispatch])

  const onCreateFinish = useCallback(() => {
    dispatch(getAllUsers())
    reset()
    setUserType(null)
    onClose()
    setCreateUserPending(false)
  }, [dispatch, onClose, reset])

  const onSubmit = useCallback(
    async (data: typeof defaultValues) => {
      setCreateUserPending(true)
      const { user, phone, company } = data
      const roleIds = roleIdsState.filter(isNotEmpty)
      if (!user || (!withoutRoles && !roleIds.length)) {
        return
      }
      const firstName = data.firstName.trim()
      const lastName = data.lastName.trim()
      if (userType === UserType.NEW) {
        const { id } = await dispatch(
          createUser({
            email: user.email,
            firstName,
            lastName,
            phone,
            companyId: company?.id || null,
            notify: withoutRoles,
          }),
        ).unwrap()
        if (!id) {
          onClose()
          toastr.info(
            '',
            'Возникла ошибка при добавлении пользователя, обратитесь к разработчикам.',
            {
              icon: <div />,
            },
          )
          return
        }
        if (withoutRoles) {
          onCreateFinish()
          return
        }
        await dispatch(
          addUserRoles({
            roleIds,
            id,
            projectUrn,
            name: `${lastName} ${firstName[0]}.`,
            toastrText: 'Пользователь добавлен',
          }),
        )
      } else {
        const { id, name, firstName, lastName, email, phone } = user
        const promises: Promise<unknown>[] = [
          dispatch(
            addUserRoles({
              roleIds,
              id,
              projectUrn,
              name,
              toastrText:
                userType === UserType.ACCOUNT
                  ? 'Пользователь добавлен'
                  : 'Пользователь получил новые роли',
            }),
          ),
        ]
        if (user.companyId !== company?.id && (user.companyId || company?.id)) {
          promises.push(
            dispatch(
              updateUser({
                id,
                email,
                firstName: firstName || '',
                lastName: lastName || '',
                phone: phone || '',
                companyId: company?.id || null,
              }),
            ),
          )
        }
        await Promise.all(promises)
      }
      //TODO: убрать, когда можно будет нормально выбирать и проект и роль в попапе
      if (!withoutRoles) {
        dispatch(getProjectUsers({ projectUrn }))
      }
      onCreateFinish()
    },
    [
      roleIdsState,
      withoutRoles,
      userType,
      onCreateFinish,
      dispatch,
      projectUrn,
      onClose,
    ],
  )

  useEffect(() => {
    dispatch(getRoles({ projectUrn }))
  }, [dispatch, projectUrn])

  const newUserMock: User = {
    id: UserType.NEW,
    email: newUserEmail,
    name: '',
    phone: '',
    userRoles: [],
    companyId: null,
    firstName: '',
    lastName: '',
  }
  const selectedUser = getValues().user

  useEffect(() => {
    setValue('company', companiesSet[selectedUser?.companyId || ''])
  }, [selectedUser?.companyId, companiesSet, setValue])

  const submitDisabled =
    (userType === UserType.NEW && (!!errors.firstName || !!errors.lastName)) ||
    !!errors.user ||
    (!roleIdsState.some(id => id) && !withoutRoles) ||
    createUserPending

  useEffect(() => {
    const controlString = newUserEmail.trim().toLowerCase()
    const newFilteredUsers = allUsers.filter(u =>
      u.email?.includes(controlString),
    )
    setFilteredUsers(
      newFilteredUsers.length < 50
        ? newFilteredUsers
        : newFilteredUsers.slice(0, 49),
    )
  }, [allUsers, newUserEmail])

  const controlString = newUserEmail.trim().toLowerCase()
  const exactExistingUser =
    filteredUsers.length < 5
      ? filteredUsers.some(u => u.email === controlString)
      : false

  const handleChange = (cb: (val: User) => void, val: User | null) => {
    if (!val) {
      return
    }
    cb(val)
    if (val.id !== UserType.NEW) {
      setRoleIdsState([null])
    }
    setUserType(getUserType(val, projectUrn))
  }
  const getUserOptions = () => {
    if (withoutRoles) {
      return exactExistingUser ? [] : [newUserMock]
    }

    return newUserEmail.trim() && !exactExistingUser
      ? [newUserMock, ...filteredUsers]
      : filteredUsers
  }

  return (
    <ActionPopupWrapper
      title={
        <span data-test-id='addUserPopupHeading'>Добавить пользователя</span>
      }
      submitButtonTitle='Добавить'
      pending={submitDisabled}
      open={open}
      width='600px'
      onClose={() => {
        reset()
        setUserType(null)
        onClose()
      }}
      onSubmit={handleSubmit(onSubmit)}
      dataTestIdFor={popupButtonsDataTestIds}
    >
      {({ existScroll }) => (
        <Form>
          <FieldContainer>
            <FieldLabel required>Email</FieldLabel>
            <Controller
              name='user'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
              }}
              render={({ field }) => (
                <SelectWrapper userType={userType}>
                  <Select
                    {...field}
                    onChange={val => {
                      if (withoutRoles && val?.id !== UserType.NEW) {
                        return
                      }
                      handleChange(field.onChange, val)
                    }}
                    error={
                      Boolean(errors.user) || userType === UserType.PROJECT
                    }
                    options={getUserOptions()}
                    placeholder='Начните вводить для поиска'
                    getOptionLabel={(option: User) => option.email}
                    emptyOptionListMessage='Нет доступных для добавления пользователей'
                    onInputChange={setNewUserEmail}
                    onReset={() => {
                      setUserType(null)
                      setRoleIdsState([null])
                      reset()
                    }}
                    data-test-id='emailInput'
                    renderOption={({
                      idx,
                      option,
                      onClose,
                      active,
                      hover,
                      onMouseEnterOption,
                    }) => {
                      const currProjectOptionRoles = option.userRoles.find(
                        r => r.projectUrn === projectUrn,
                      )?.roles

                      return (
                        <UserOption
                          data-test-id={`emailUserOption_${idx}`}
                          disabled={withoutRoles && option.id !== UserType.NEW}
                          key={idx}
                          hover={hover}
                          active={active}
                          onClick={() => {
                            if (withoutRoles && option.id !== UserType.NEW) {
                              return
                            }
                            handleChange(field.onChange, option)
                            onClose()
                          }}
                          onMouseEnter={() => onMouseEnterOption(idx)}
                        >
                          {option.id !== UserType.NEW ? (
                            <>
                              <UserInfoWrapper>
                                <UserBadge bg='rgba(76, 94, 207, 0.15)'>
                                  {`${option.name[0]}${
                                    option.name[option.name.indexOf(' ') + 1]
                                  }`}
                                </UserBadge>
                                <TextBlock>
                                  <Name>{option.name}</Name>
                                </TextBlock>
                                {currProjectOptionRoles &&
                                currProjectOptionRoles.length ? (
                                  <>
                                    <RoleBadge
                                      roleId={currProjectOptionRoles[0].id}
                                      roleTitle={
                                        currProjectOptionRoles[0].title
                                      }
                                    />
                                    {currProjectOptionRoles.length - 1 ? (
                                      <AdditionalRolesCount>{`+${
                                        currProjectOptionRoles.length - 1
                                      }`}</AdditionalRolesCount>
                                    ) : null}
                                  </>
                                ) : null}
                              </UserInfoWrapper>
                              <EmailAndPhone>
                                <span>{option.email}</span>
                                <span>{option.phone}</span>
                              </EmailAndPhone>
                            </>
                          ) : (
                            <>
                              <UserInfoWrapper>
                                <Avatar />
                                <TextBlock>
                                  <Name>{option.email}</Name>
                                </TextBlock>
                                <RoleBadge />
                              </UserInfoWrapper>
                              <EmailAndPhone>
                                <span>{option.email}</span>
                                <span>{option.phone}</span>
                              </EmailAndPhone>
                            </>
                          )}
                        </UserOption>
                      )
                    }}
                    disabled={allUsersPending}
                  />
                  {userType && <RoleBadge roleId={userType} />}
                </SelectWrapper>
              )}
            />
            <FieldError hidden={!errors.user}>
              {errors.user && 'message' in errors.user
                ? errors.user.message
                : null}
            </FieldError>
          </FieldContainer>
          {userType ? (
            <UserTypeInfoPanel userType={userType} user={getValues('user')} />
          ) : null}
          {userType === UserType.NEW ? (
            <>
              <FieldContainer>
                <FieldLabel required>Имя</FieldLabel>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: {
                      message: requiredErrorMessage,
                      value: userType === UserType.NEW,
                    },
                    pattern: {
                      value: /[^\s]/u,
                      message: 'Неверный формат',
                    },
                    maxLength: { value: 40, message: maxLengthErrorMessage },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.firstName)}
                      clearable
                      data-test-id='firstNameInput'
                    />
                  )}
                />
                <FieldError hidden={!errors.firstName}>
                  {errors.firstName && 'message' in errors.firstName
                    ? errors.firstName.message
                    : null}
                </FieldError>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel required>Фамилия</FieldLabel>
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    required: {
                      message: requiredErrorMessage,
                      value: userType === UserType.NEW,
                    },
                    pattern: {
                      value: /[^\s]/u,
                      message: 'Неверный формат',
                    },
                    maxLength: { value: 40, message: maxLengthErrorMessage },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.lastName)}
                      clearable
                      data-test-id='lastNameInput'
                    />
                  )}
                />
                <FieldError hidden={!errors.lastName}>
                  {errors.lastName && 'message' in errors.lastName
                    ? errors.lastName.message
                    : null}
                </FieldError>
              </FieldContainer>
              <FieldContainer>
                <FieldLabel>Телефон</FieldLabel>
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        // eslint-disable-next-line prefer-named-capture-group
                        /(^\+?\d{1,3})[ -]?(\d{3})[ -]?(\d{3})[ -]?(\d{2})[ -]?(\d{2})[ -]?$/mu,
                      message: 'Неверный формат',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.phone)}
                      clearable
                      data-test-id='phoneInput'
                    />
                  )}
                />
                <FieldError hidden={!errors.phone}>
                  {errors.phone && 'message' in errors.phone
                    ? errors.phone.message
                    : null}
                </FieldError>
              </FieldContainer>
            </>
          ) : null}
          {selectedUser ? (
            <FieldContainer>
              <FieldLabel>Компания</FieldLabel>
              <Controller
                name='company'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={companies}
                    getOptionLabel={(option: Company) => option.companyName}
                    emptyOptionListMessage='Нет доступных совпадений'
                    placeholder='Укажите компанию'
                    data-test-id='companyInput'
                    optionDataTestIdPrefix='userCompanyOption'
                    error={Boolean(errors.company)}
                  />
                )}
              />
              <FieldError hidden={!errors.company}>
                {errors.company && 'message' in errors.company
                  ? errors.company.message
                  : null}
              </FieldError>
            </FieldContainer>
          ) : null}
          {selectedUser && !withoutRoles ? (
            <SelectMultipleRoles
              roleIds={roleIdsState}
              setRoleIds={setRoleIdsState}
              unselectableIds={
                selectedUser.userRoles
                  .find(r => r.projectUrn === projectUrn)
                  ?.roles.map(r => r.id) || []
              }
              oneInARow
            />
          ) : null}
          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}
