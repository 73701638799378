import { useState } from 'react'

import { Tooltip } from '@gmini/ui-kit'

import { RolesForUser } from '../../../store/users/types'

import {
  ArrowDown,
  Name,
  ProjectRolesLine,
  ProjectsItem,
  ProjectsItemWrapper,
  ProjectsRolesWrapper,
  RolesItemWrapper,
  ShowAllButton,
  ShowAllButtonMock,
  StyledRoleBadge,
  TooltipStyled,
} from './ProjectsRolesCell.styled'
type ProjectsCellProps = {
  userRoles: RolesForUser[]
  idx: number
  inProject: boolean
}

const visibleRolesBadgesCount = 4

export const ProjectsRolesCell = ({
  userRoles,
  idx,
  inProject,
}: ProjectsCellProps) => {
  const [isProjectsExpanded, setIsProjectsExpanded] = useState(false)
  const [chosenProjectUrn, setChosenProjectUrn] = useState('')

  const projectsRender = userRoles.map(p => (
    <TooltipStyled key={p.projectUrn} title={p.projectName || ''}>
      <Name
        onMouseEnter={() => setChosenProjectUrn(p.projectUrn)}
        onMouseLeave={() => setChosenProjectUrn('')}
        unchosen={chosenProjectUrn ? p.projectUrn !== chosenProjectUrn : false}
      >
        {p.projectName}
      </Name>
    </TooltipStyled>
  ))

  return (
    <ProjectsRolesWrapper>
      {!inProject && (
        <ProjectsItemWrapper>
          <ProjectsItem expanded={isProjectsExpanded}>
            {projectsRender}
          </ProjectsItem>
          {projectsRender.length > 2 ? (
            <ShowAllButton
              data-test-id={`showAllProjectsRowBtn_${idx}`}
              onClick={e => {
                e.stopPropagation()
                setIsProjectsExpanded(s => !s)
              }}
            >
              <span>
                {isProjectsExpanded
                  ? 'Свернуть'
                  : `+ еще ${projectsRender.length - 2}`}
              </span>
              <ArrowDown expanded={isProjectsExpanded} color='#4c5ecf' />
            </ShowAllButton>
          ) : null}
        </ProjectsItemWrapper>
      )}
      <RolesItemWrapper inProject={inProject} expanded={isProjectsExpanded}>
        {userRoles.map(p => (
          <ProjectRolesLine
            unchosen={
              chosenProjectUrn ? p.projectUrn !== chosenProjectUrn : false
            }
            key={p.projectUrn}
          >
            {p.roles.length > visibleRolesBadgesCount ? (
              <>
                {p.roles.slice(0, visibleRolesBadgesCount).map(r => (
                  <StyledRoleBadge
                    key={r.id}
                    roleId={r.id}
                    roleTitle={r.title}
                  />
                ))}
                <Tooltip
                  title={p.roles
                    .slice(visibleRolesBadgesCount)
                    .reduce(
                      (acc, r) => (acc ? `${acc}, ${r.title}` : r.title),
                      '',
                    )}
                >
                  <StyledRoleBadge
                    roleTitle={`+${p.roles.length - visibleRolesBadgesCount}`}
                  />
                </Tooltip>
              </>
            ) : (
              p.roles.map(r => (
                <StyledRoleBadge key={r.id} roleId={r.id} roleTitle={r.title} />
              ))
            )}
          </ProjectRolesLine>
        ))}
        {userRoles.length > 2 ? <ShowAllButtonMock /> : null}
      </RolesItemWrapper>
    </ProjectsRolesWrapper>
  )
}
