import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { apiClient } from '../../services/api'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'

import { modulesNames } from '../modules/types'

import { downloadFile } from '../utils'

import { AvailableModules, User, UserWithHubPermissions } from './types'

export const getUserById = createAsyncThunk<User, { id: string }>(
  'users/getUserById',
  async ({ id }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<User>({
      path: `/auth/admin/users/${id}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: User = get(res, 'data.data', {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      permissions: [],
      userRoles: [],
      companyId: null,
    })

    return data
  },
)

export const createUser = createAsyncThunk<
  User,
  {
    firstName: string
    lastName: string
    email: string
    phone: string
    companyId: number | null
    notify?: boolean
  }
>(
  'users/createUser',
  async (
    { firstName, lastName, email, phone, companyId, notify },
    { rejectWithValue },
  ) => {
    const [err, res] = await apiClient.post<
      {
        firstName: string
        lastName: string
        email: string
        phone: string
        companyId: number | null
      },
      User
    >({
      path: '/auth/admin/users',
      body: {
        firstName,
        lastName,
        email,
        phone,
        companyId,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: User = get(res, 'data.data', {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      id: '',
      userRoles: [],
      email: '',
      companyId: null,
    })

    if (notify) {
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>
            Пользователь создан: <span>{data.name}</span>
          </SnackbarWrapper>
        ),
      })
    }

    return data
  },
)

export const updateUser = createAsyncThunk<
  void,
  {
    id: string
    phone: string
    email: string
    companyId: number | null
    firstName: string
    lastName: string
  }
>(
  'users/updateUser',
  async (
    { id, phone, email, companyId, firstName, lastName },
    { rejectWithValue },
  ) => {
    const [err] = await apiClient.put<
      {
        phone: string
        companyId: number | null
        firstName: string
        lastName: string
      },
      User
    >({
      path: `/auth/admin/users/${id}`,
      body: {
        phone,
        companyId,
        firstName,
        lastName,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          Пользователь изменен: <span>{`${lastName} ${firstName}`}</span>
        </SnackbarWrapper>
      ),
    })
  },
)

export const addUserRoles = createAsyncThunk<
  void,
  {
    id: string
    projectUrn: string
    roleIds: number[]
    toastrText?: string
    name: string
  }
>(
  'users/addUserRoles',
  async (
    { id, roleIds, projectUrn, toastrText, name },
    { rejectWithValue },
  ) => {
    const [err] = await apiClient.post<
      {
        roleIds: number[]
      },
      void
    >({
      path: `/auth/admin/users/${id}/project/${projectUrn}/add-roles`,
      body: {
        roleIds,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    if (toastrText) {
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>
            {toastrText}: <span>{name}</span>
          </SnackbarWrapper>
        ),
      })
    }
  },
)

export const deleteUserRoles = createAsyncThunk<
  void,
  {
    id: string
    projectUrn: string
    roleIds: number[]
    name: string
    toastrText: string
  }
>(
  'users/deleteUserRoles',
  async (
    { id, projectUrn, roleIds, name, toastrText },
    { rejectWithValue },
  ) => {
    const [err] = await apiClient.post<{ roleIds: number[] }, void>({
      path: `/auth/admin/users/${id}/project/${projectUrn}/remove-roles`,
      body: {
        roleIds,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          {toastrText}: <span>{name}</span>
        </SnackbarWrapper>
      ),
    })
  },
)

export const getCompanyUsers = createAsyncThunk<User[], { companyId: string }>(
  'users/getCompanyUsers',
  async ({ companyId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<User[]>({
      path: `/auth/admin/users`,
      params: {
        'company-id': companyId,
        'with-project-name': true,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: User[] = get(res, 'data.data', [])

    return data
  },
)

export const getProjectUsers = createAsyncThunk<User[], { projectUrn: string }>(
  'users/getProjectUsers',
  async ({ projectUrn }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<User[]>({
      path: `/auth/admin/users`,
      params: {
        'project-urn': projectUrn,
        'with-project-name': true,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: User[] = get(res, 'data.data', [])

    return data
  },
)

export const getModuleUsers = createAsyncThunk<
  User[],
  { moduleName: keyof typeof modulesNames }
>('users/getModuleUsers', async ({ moduleName }, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<User[]>({
    path: `/auth/admin/users`,
    params: {
      'module-name': moduleName,
      'with-project-name': true,
    },
  })

  if (err) {
    throw rejectWithValue(err)
  }

  const data: User[] = get(res, 'data.data', [])

  return data
})

export const getAllUsers = createAsyncThunk<User[], void>(
  'users/getAllUsers',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<User[]>({
      path: '/auth/admin/users',
      params: {
        'with-project-name': true,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: User[] = get(res, 'data.data', [])

    return data
  },
)

export const getAvailableModules = createAsyncThunk<AvailableModules, void>(
  'users/getAvailableModules',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<AvailableModules>({
      path: '/auth/users/modules-with-hosts',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: AvailableModules = get(res, 'data.data', [])

    return data
  },
)

export const getCurrentUser = createAsyncThunk<UserWithHubPermissions, void>(
  'users/getCurrentUser',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<UserWithHubPermissions>({
      path: '/auth/current-user',
      params: {
        'retrieve-permissions': true,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: UserWithHubPermissions = get(res, 'data.data', {
      id: '',
      name: '',
      email: '',
      hubPermissions: {},
    })

    return data
  },
)

export const getProjectsUsersCount = createAsyncThunk<
  { projectUrn: string; count: number }[],
  void
>('projects/getProjectsUsersCount', async (_, { rejectWithValue }) => {
  const [err, res] = await apiClient.get({
    path: '/auth/admin/users/count',
  })
  if (err) {
    throw rejectWithValue(err)
  }

  const data: { projectUrn: string; count: number }[] = get(
    res,
    'data.data',
    [],
  )

  return data
})

export const getCompaniesUsersCount = createAsyncThunk<
  { companyId: string; count: number }[],
  void
>('projects/getCompaniesUsersCount', async (_, { rejectWithValue }) => {
  const [err, res] = await apiClient.get({
    path: '/auth/admin/users/count-by-companies',
  })
  if (err) {
    throw rejectWithValue(err)
  }

  const data: { companyId: string; count: number }[] = get(res, 'data.data', [])

  return data
})

export const exportUsersXls = createAsyncThunk<void, void>(
  'projects/exportUsersXls',
  async (_, { rejectWithValue }) => {
    const [err, blob] = await apiClient.get<Blob>({
      path: '/auth/admin/users/export',
      responseType: 'blob',
    })
    if (err) {
      throw rejectWithValue(err)
    }
    downloadFile(blob)
  },
)
