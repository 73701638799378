import { UserInfo } from '@gmini/common'

import { Module, PermissionsDto } from '../roles/types'

export type RolesForUser = {
  roles: {
    id: number
    title: string
  }[]
  projectUrn: string
  projectName?: string
}

export type User = {
  id: string
  name: string
  firstName: string | null
  lastName: string | null
  email: string
  phone: string | null
  companyId: number | null
  userRoles: RolesForUser[]
}

export type UserInfoWithPermissions = UserInfo & {
  permissions: PermissionsDto[]
  roles: RolesForUser[]
}

export type UserWithHubPermissions = UserInfo & {
  hubPermissions: { [x: string]: { [x: string]: string[] } }
}

export type AvailableModules = {
  module: Omit<Module, 'resources'>
  enabled: boolean
  url: string | null
}[]

export enum UserType {
  NEW = 'Новый пользователь',
  ACCOUNT = 'Пользователь аккаунта',
  PROJECT = 'Пользователь проекта',
}
