import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'

import {
  AddAfter,
  PenOutlined,
  PlusCircle,
  VerticalThreeDots,
} from '@gmini/ui-kit'

import { useContextMenu } from '@gmini/common'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { AddFolderIcon } from '../icons/AddFolderIcon'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  addAttributeGroup,
  addAttributeValue,
  deleteAttribute,
  deleteAttributeGroup,
  deleteAttributeValue,
  getAttribute,
} from '../../store/attributes/actions'

import {
  Attribute,
  AttributeGroup,
  AttributeInstances,
  AttributeValue,
} from '../../store/attributes/types'

import { MoveAttributeValueModal } from '../MoveAttributeValueModal/MoveAttributeValueModal'

import { DeletePopup } from '../DeletePopup/DeletePopup'

import { Text } from '../DeletePopup/DeletePopup.styled'

import { EditOrAddAttributeModal } from '../EditOrAddAttributeModal/EditOrAddAttributeModal'

import {
  AddAttributeValueButton,
  AttributeTabContentBlock,
  AttributeTabList,
  AttributeTabListHeader,
  AttributeTabTabWrapper,
  DeletePopupTitle,
  ListHeaderButtonContainer,
  StyledIconButton,
  TabRightSideWrapper,
} from './AttributeTab.styled'
import { AttributeValueGroupItem } from './AttributeValueGroupItem'
import { AttributeValueItem } from './AttributeValueItem'
import { StyledAttributeInput } from './AttributeValueGroupItem.styled'
import { checkNodeAccordance } from './utils'
import { AttributesInfo } from './AttributesInfo'

type AttributeTabProps = {
  attribute: Attribute
  setSelectedAttribute: Dispatch<SetStateAction<Attribute | null>>
}

type tabStateType = {
  attributeValueToMove: AttributeValue | null
  attributeValueToDelete: AttributeValue | null
  attributeGroupToDelete: AttributeGroup | null
  valueToEdit: AttributeValue | AttributeGroup | null
  isAttributeValue: boolean
  isInputOpen: boolean
  isDeleteAttributeOpen: boolean
  isEditAttributeOpen: boolean
}

type BooleanAction = {
  type:
    | 'setIsAttributeValue'
    | 'setIsInputOpen'
    | 'setIsDeleteAttributeOpen'
    | 'setIsEditAttributeOpen'
  payload: boolean
}

type ValueAction = {
  type: 'setAttributeValueToMove' | 'setAttributeValueToDelete'
  payload: AttributeValue | null
}

type GroupAction = {
  type: 'setAttributeGroupToDelete'
  payload: AttributeGroup | null
}

type ValueGroupAction = {
  type: 'setValueToEdit'
  payload: AttributeValue | AttributeGroup | null
}

const initTabState = {
  attributeValueToMove: null,
  attributeValueToDelete: null,
  attributeGroupToDelete: null,
  valueToEdit: null,
  isAttributeValue: false,
  isInputOpen: false,
  isDeleteAttributeOpen: false,
  isEditAttributeOpen: false,
}

const reducer = (
  state: tabStateType,
  action: BooleanAction | ValueAction | GroupAction | ValueGroupAction,
) => {
  const { type, payload } = action
  switch (type) {
    case 'setAttributeValueToMove':
      return { ...state, attributeValueToMove: payload }
    case 'setAttributeValueToDelete':
      return { ...state, attributeValueToDelete: payload }
    case 'setAttributeGroupToDelete':
      return { ...state, attributeGroupToDelete: payload }
    case 'setValueToEdit':
      return { ...state, valueToEdit: payload }
    case 'setIsAttributeValue':
      return { ...state, isAttributeValue: payload }
    case 'setIsInputOpen':
      return { ...state, isInputOpen: payload }
    case 'setIsDeleteAttributeOpen':
      return { ...state, isDeleteAttributeOpen: payload }
    case 'setIsEditAttributeOpen':
      return { ...state, isEditAttributeOpen: payload }
    default:
      throw new Error(
        `Unhandled expression in switch: ${(action as any)?.type}`,
      )
  }
}

export const AttributeTab = ({
  attribute,
  setSelectedAttribute,
}: AttributeTabProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [tabState, dispatchTabAction] = useReducer(reducer, initTabState)

  const {
    attributeGroupToDelete,
    attributeValueToDelete,
    attributeValueToMove,
    isAttributeValue,
    isDeleteAttributeOpen,
    isEditAttributeOpen,
    isInputOpen,
    valueToEdit,
  } = tabState

  const { id: attributeId, name } = attribute

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isInputOpen) {
      inputRef.current?.focus()
    }
  }, [isInputOpen])

  useEffect(() => {
    dispatch(getAttribute({ attributeId }))
  }, [dispatch, attributeId])

  const { children } = useAppSelector(state => state.currentAttribute)

  const groupList = useMemo(
    () =>
      children.filter(
        (v): v is AttributeGroup => v.type === AttributeInstances.GROUP,
      ),
    [children],
  )

  const handleAddAttributeValueGroup = useCallback(
    (name: string) => {
      dispatch(addAttributeGroup({ name, attributeId }))
    },
    [dispatch, attributeId],
  )

  const handleAddAttributeValue = useCallback(
    (name: string) => {
      dispatch(addAttributeValue({ name, attributeId, parentGroupId: 0 }))
    },
    [dispatch, attributeId],
  )

  const {
    ContextMenu: ListItemContextMenu,
    setCtxMenu: setListItemCtxMenu,
    ctxMenu: listItemCtxMenu,
  } = useContextMenu<{
    item: AttributeValue | AttributeGroup
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      dataTestId: 'attributeValuesListCtxMenuEdit',
      onClick: ({ item }) =>
        dispatchTabAction({ type: 'setValueToEdit', payload: item }),
      customIcon: <PenOutlined />,
    },
    {
      title: 'Переместить',
      dataTestId: 'attributeValuesListCtxMenuMove',
      show: ({ item: { type } }) => type === AttributeInstances.VALUE,
      onClick: ({ item }) =>
        item.type === AttributeInstances.VALUE &&
        dispatchTabAction({ type: 'setAttributeValueToMove', payload: item }),
      customIcon: <AddAfter />,
    },
    {
      dataTestId: 'attributeValuesListCtxMenuDelete',
      title: 'Удалить',
      onClick: ({ item }) =>
        item.type === AttributeInstances.GROUP
          ? dispatchTabAction({
              type: 'setAttributeGroupToDelete',
              payload: item,
            })
          : dispatchTabAction({
              type: 'setAttributeValueToDelete',
              payload: item,
            }),
      icon: Icon.DELETE,
    },
  ])

  const { ContextMenu: AttributeContextMenu, setCtxMenu: setAttributeCtxMenu } =
    useContextMenu<{
      item: Attribute
      event: MouseEvent
    }>([
      {
        title: 'Редактировать атрибут',
        dataTestId: 'attributeCtxMenuEdit',
        onClick: () =>
          dispatchTabAction({ type: 'setIsEditAttributeOpen', payload: true }),
        customIcon: <PenOutlined />,
      },
      {
        dataTestId: 'attributeCtxMenuDelete',
        title: 'Удалить атрибут',
        onClick: () =>
          dispatchTabAction({
            type: 'setIsDeleteAttributeOpen',
            payload: true,
          }),
        icon: Icon.DELETE,
      },
    ])

  const onListItemActionButtonClick = (
    e: MouseEvent,
    item: AttributeValue | AttributeGroup,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setListItemCtxMenu({
      coords: { x: e.clientX, y: e.clientY },
      item: { item, event: e },
    })
  }

  const handleSubmitDelete = (type: AttributeInstances, id?: number) => {
    if (!id) {
      return
    }
    if (type === AttributeInstances.ATTRIBUTE) {
      dispatch(deleteAttribute({ attributeId: id, name }))
      dispatchTabAction({ type: 'setIsDeleteAttributeOpen', payload: false })
    } else if (type === AttributeInstances.GROUP) {
      dispatch(deleteAttributeGroup({ attributeId, name, groupId: id }))
      dispatchTabAction({ type: 'setAttributeGroupToDelete', payload: null })
    } else {
      dispatch(
        deleteAttributeValue({
          attributeId,
          name,
          valueId: id,
          groupId: attributeValueToDelete?.parentGroupId,
        }),
      )
      dispatchTabAction({ type: 'setAttributeValueToDelete', payload: null })
    }
  }

  return (
    <AttributeTabTabWrapper>
      <ListItemContextMenu />
      <AttributeContextMenu />
      <AttributeTabContentBlock>
        <AttributeTabListHeader>
          <span>Значения атрибутов</span>
          <ListHeaderButtonContainer>
            <AddAttributeValueButton
              data-test-id='attributeTabAddGroupBtn'
              onClick={() => {
                dispatchTabAction({
                  type: 'setIsAttributeValue',
                  payload: false,
                })
                dispatchTabAction({ type: 'setIsInputOpen', payload: true })
              }}
            >
              <AddFolderIcon />
              <span>Группировка</span>
            </AddAttributeValueButton>
            <AddAttributeValueButton
              data-test-id='attributeTabAddValueBtn'
              onClick={() => {
                dispatchTabAction({
                  type: 'setIsAttributeValue',
                  payload: true,
                })
                dispatchTabAction({ type: 'setIsInputOpen', payload: true })
              }}
            >
              <PlusCircle color='#353B60' />
              <span>Значение</span>
            </AddAttributeValueButton>
          </ListHeaderButtonContainer>
        </AttributeTabListHeader>
        <AttributeTabList>
          {children.map((d, idx) =>
            d.type === AttributeInstances.GROUP ? (
              <AttributeValueGroupItem
                dataTestIdPrefix={`attributeValuesListItem_${idx}`}
                onActionButtonClick={onListItemActionButtonClick}
                key={d.id}
                group={d}
                ctxMenu={listItemCtxMenu}
                valueToEdit={valueToEdit}
                stopEditing={() =>
                  dispatchTabAction({ type: 'setValueToEdit', payload: null })
                }
              />
            ) : (
              <AttributeValueItem
                dataTestIdPrefix={`attributeValuesListItem_${idx}`}
                onActionButtonClick={onListItemActionButtonClick}
                key={d.id}
                value={d}
                active={checkNodeAccordance(
                  AttributeInstances.VALUE,
                  d.id,
                  listItemCtxMenu.item?.item,
                )}
                isEditing={checkNodeAccordance(
                  AttributeInstances.VALUE,
                  d.id,
                  valueToEdit,
                )}
                stopEditing={() =>
                  dispatchTabAction({ type: 'setValueToEdit', payload: null })
                }
              />
            ),
          )}
          {isInputOpen ? (
            <StyledAttributeInput
              dataTestIdPrefix='attributeValuesListRootLevel'
              isAttributeValue={isAttributeValue}
              inputRef={inputRef}
              onClose={() =>
                dispatchTabAction({ type: 'setIsInputOpen', payload: false })
              }
              onSubmit={
                isAttributeValue
                  ? handleAddAttributeValue
                  : handleAddAttributeValueGroup
              }
            />
          ) : null}
        </AttributeTabList>
      </AttributeTabContentBlock>
      <TabRightSideWrapper>
        <StyledIconButton
          data-test-id='attributeTabActionsBtn'
          onClick={(e: MouseEvent) => {
            setAttributeCtxMenu({
              coords: { x: e.clientX, y: e.clientY },
              item: { item: attribute, event: e },
            })
          }}
          size='regular'
        >
          <VerticalThreeDots />
        </StyledIconButton>
        <AttributesInfo />
      </TabRightSideWrapper>
      <MoveAttributeValueModal
        onClose={() =>
          dispatchTabAction({ type: 'setAttributeValueToMove', payload: null })
        }
        attributeValueToMove={attributeValueToMove}
        groupList={groupList}
      />
      <DeletePopup
        title={
          <DeletePopupTitle>{'Удалить значение атрибута'}</DeletePopupTitle>
        }
        dataTestIdPrefix='AttributeValue'
        open={Boolean(attributeValueToDelete)}
        onClose={() =>
          dispatchTabAction({
            type: 'setAttributeValueToDelete',
            payload: null,
          })
        }
        handleSubmit={() =>
          handleSubmitDelete(
            AttributeInstances.VALUE,
            attributeValueToDelete?.id,
          )
        }
        noCross
        width={'600px'}
      >
        <Text>
          Вы уверены что хотите удалить значение атрибута{' '}
          <span>“{attributeValueToDelete?.name}”</span>, используемое в проектах
          аккаунта?
        </Text>
      </DeletePopup>
      <DeletePopup
        title={<DeletePopupTitle>{'Удалить группировку'}</DeletePopupTitle>}
        dataTestIdPrefix='AttributeGroup'
        open={Boolean(attributeGroupToDelete)}
        onClose={() =>
          dispatchTabAction({
            type: 'setAttributeGroupToDelete',
            payload: null,
          })
        }
        handleSubmit={() =>
          handleSubmitDelete(
            AttributeInstances.GROUP,
            attributeGroupToDelete?.id,
          )
        }
        noCross
        width={'600px'}
      >
        <Text>
          Вы уверены что хотите удалить группировку значений атрибутов{' '}
          <span>“{attributeGroupToDelete?.name}”</span>?
        </Text>
      </DeletePopup>
      <DeletePopup
        title={<DeletePopupTitle>{'Удалить атрибут'}</DeletePopupTitle>}
        dataTestIdPrefix='Attribute'
        open={isDeleteAttributeOpen}
        onClose={() =>
          dispatchTabAction({
            type: 'setIsDeleteAttributeOpen',
            payload: false,
          })
        }
        handleSubmit={() => {
          handleSubmitDelete(AttributeInstances.ATTRIBUTE, attributeId)
          setSelectedAttribute(null)
        }}
        noCross
        width={'500px'}
      >
        <Text>
          Атрибут <span>“{name}”</span>, используемый в проектах аккаунта, вы
          уверены что хотите удалить? <br />
          После подтверждения, это действие не может быть отменено.
        </Text>
      </DeletePopup>
      <EditOrAddAttributeModal
        open={isEditAttributeOpen}
        onClose={() =>
          dispatchTabAction({ type: 'setIsEditAttributeOpen', payload: false })
        }
        attribute={attribute}
        setSelected={setSelectedAttribute}
      />
    </AttributeTabTabWrapper>
  )
}
