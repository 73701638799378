import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { getRoles } from '../../store/roles/actions'
import { Role } from '../../store/roles/types'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { AddRolePopup } from '../AddRolePopup/AddRolePopup'
import { AddUserIcon } from '../icons/AddUserIcon'
import { LockIcon } from '../icons/LockIcon'
import { RoleProfile } from '../RoleProfile/RoleProfile'
import { RolesListItem } from '../RolesListItem/RolesListItem'

import {
  checkRolesPermissionsSelector,
  ModuleScopes,
} from '../../store/users/permissions-selectors'

import {
  AddButton,
  ButtonContainer,
  ListHeading,
  RolesList,
  ScrollbarStyled,
  Title,
  TitleCount,
  RoleListWrapper,
  RolePageWrap,
} from './RolesPage.styled'

export const RolesPage = () => {
  const dispatch = useAppDispatch()
  const [selectedRole, setSelectedRole] = useState<Role | null>(null)
  const [changesMap, setChangesMap] = useState<{ [x: string]: number }>({})
  const [saveChangesPopupOpen, setSaveChangesPopupOpen] = useState(false)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)
  const { projectUrn } = useParams<{
    projectUrn: string
    hubId: string
  }>()
  const { list: roles, total } = useAppSelector(state => state.roles)
  const history = useHistory()

  useEffect(() => {
    const unblock = history.block(() => {
      if (Object.values(changesMap).reduce((acc, n) => acc + n, 0)) {
        setSaveChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, changesMap])
  const checkRolesPermissions = useAppSelector(checkRolesPermissionsSelector)

  useEffect(() => {
    dispatch(getRoles({ projectUrn }))
  }, [dispatch, projectUrn])

  return (
    <RolePageWrap>
      <RoleListWrapper>
        <ListHeading>
          <Title>
            <LockIcon />
            <span data-test-id='rolesPageHeading'>Роли</span>{' '}
            <TitleCount>{total}</TitleCount>
          </Title>
          <ButtonContainer>
            <AddButton
              data-test-id='addRoleBtn'
              onClick={() => setAddUserPopupOpen(true)}
              size='regular'
              color='primary'
              leftIcon={<AddUserIcon width='16px' height='16px' />}
              disabled={!checkRolesPermissions(ModuleScopes.AC_CREATE)}
            >
              <span>Добавить роль</span>
            </AddButton>
          </ButtonContainer>
        </ListHeading>
        <AddRolePopup
          open={addUserPopupOpen}
          onClose={() => setAddUserPopupOpen(false)}
          setSelectedRole={setSelectedRole}
        />
        <ScrollbarStyled>
          <RolesList>
            {roles.map((r, idx) => {
              const selected = selectedRole?.id === r.id
              return (
                <RolesListItem
                  data-test-id={`roleItem_${idx}${r.system ? '_system' : ''}`}
                  key={r.id}
                  selected={selected}
                  hubRole={r}
                  onClick={() => {
                    if (
                      Object.values(changesMap).reduce((acc, n) => acc + n, 0)
                    ) {
                      setSaveChangesPopupOpen(true)
                    } else {
                      setSelectedRole(r)
                    }
                  }}
                />
              )
            })}
          </RolesList>
        </ScrollbarStyled>
      </RoleListWrapper>
      {selectedRole ? (
        <RoleProfile
          role={selectedRole}
          setSelectedRole={setSelectedRole}
          changesMap={changesMap}
          setChangesMap={setChangesMap}
          saveChangesPopupOpen={saveChangesPopupOpen}
          setSaveChangesPopupOpen={setSaveChangesPopupOpen}
        />
      ) : null}
    </RolePageWrap>
  )
}
